<template>
  <img
    class="image-fill color.invert($color)  rounded-full outline outline-2"
    :class="size === 'small' ? 'h-6 w-6' : 'h-7 lg:h-9 w-7 lg:w-9'"
    :src="user.avatar"
    alt=""
  >
</template>

<script setup lang="ts">
const user = useSofieUser();

withDefaults(defineProps<{
  size?: 'small' | 'large'
}>(), {
  size: 'small',
});
</script>
