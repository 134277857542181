<template>
  <div class="flex items-center gap-3">
    <AppHeaderProfileUserAvatar size="large" />
    <div class="text-sm">
      {{ user.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
const user = useSofieUser();
</script>
