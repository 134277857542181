<template>
  <transition
    enter-active-class="transition-all duration-300 ease-in-out"
    leave-active-class="transition-all duration-300 ease-in-out"
    :enter-from-class="classes"
    :leave-to-class="classes"
    @leave="$emit('leave')"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
const props = defineProps<{
  direction: 'up' | 'down' | 'left' | 'right'
}>();

defineEmits<{
  (e: 'leave'): void
}>();

const classes = computed(() => {
  return `transition-slide-direction-${props.direction}`;
});
</script>

<style>
.transition-slide-direction-down {
  @apply translate-y-[-30px];
}

.transition-slide-direction-left {
  @apply translate-x-[30px];
}

.transition-slide-direction-right {
  @apply translate-x-[-30px];
}

.transition-slide-direction-up {
  @apply translate-y-[30px] opacity-0;
}
</style>
