<template>
  <nav
    class="pointer-events-none sticky top-0 z-[100000] transition-all duration-500 max-lg:h-[60px]"
    :class="isScrolled ? 'h-[60px] lg:h-[80px]' : 'h-[80px] lg:h-[120px]'"
  >
    <div
      class="nav-shadow-bottom pointer-events-auto h-full"
      :class="[dark ? 'bg-black-900 text-white' : 'bg-white text-black-900']"
    >
      <!--  Mobile  -->
      <div class="flex h-full items-stretch justify-between pl-5 lg:hidden">
        <NuxtLink
          class="self-center"
          :to="{path: '/'}"
        >
          <NuxtImg
            v-if="tenant?.active_image"
            alt="Mozaiek logo"
            class="h-5"
            :src="tenant.active_image.url"
          />
          <img
            v-else
            alt="Mozaiek logo"
            class="h-5"
            :src="dark ? '/images/logo-white-header.svg' : '/images/logo-0318.svg'"
          >
        </NuxtLink>

        <div class="ml-4 flex shrink-0 items-stretch">
          <a
            v-if="!user"
            aria-label="Mijn Mozaiek"
            class="p-5"
            :href="dashboardUrl"
          >
            <IconsPerson height="18" />
          </a>
          <button
            v-else
            aria-label="Mijn Mozaiek"
            class="p-4"
            type="button"
            @click="isProfileOpen = true"
          >
            <AppHeaderProfileUserAvatar :user="user" />
          </button>

          <button
            aria-label="Open menu"
            class="p-5"
            type="button"
            @click="isMobileMenuOpen = true"
          >
            <IconsHamburgerMenu height="18" />
          </button>
        </div>
      </div>

      <!--  Desktop  -->
      <div
        class="hidden h-[120px] select-none items-center gap-10 px-8 font-black transition-all duration-500 lg:flex xl:gap-14"
        :class="{'h-[80px]': isScrolled}"
      >
        <NuxtLink :to="{path: '/'}">
          <NuxtImg
            v-if="tenant?.active_image"
            alt="Mozaiek logo"
            class="h-8"
            :src="tenant.active_image.url"
          />
          <img
            v-else
            alt="Mozaiek logo"
            class="h-8"
            :src="dark ? '/images/logo-white-header.svg' : '/images/logo-0318.svg'"
          >
        </NuxtLink>

        <ul
          v-if="defaultMenu"
          class="grid grid-flow-col items-center gap-6 xl:gap-8"
        >
          <li
            v-for="(link, index) in defaultMenu"
            :key="index"
          >
            <NuxtLink
              :to="link.url"
              class="link link-nav"
              :target="link.target"
              @click="close"
            >
              {{ link.label }}
            </NuxtLink>
          </li>
          <li>
            <AppHeaderSearch />
          </li>
        </ul>

        <div
          v-if="user"
          class="relative ml-auto mr-4"
          @click="isProfileOpen = !isProfileOpen"
        >
          <div class="link link-nav relative z-20 flex cursor-pointer items-center gap-3">
            <AppHeaderProfileUser :user="user" />
            <IconsCaret
              class="link link-nav transition-transform"
              :class="{'rotate-180': isProfileOpen}"
            />
          </div>

          <div
            class="nav-shadow-bottom absolute -inset-x-6 -top-4 origin-top-right border border-black-500/10 px-6 pb-4 pt-20 font-extrabold transition duration-200"
            :class="[
              dark ? 'bg-black-900' : 'bg-white',
              isProfileOpen ? '' : 'pointer-events-none scale-y-75 opacity-0',
            ]"
          >
            <ul class="space-y-2">
              <template
                v-for="dashboardMenuItem in dashboardMenu"
                :key="dashboardMenuItem.label"
              >
                <li v-if="dashboardMenuItem.if?.() ?? true">
                  <NuxtLink
                    :to="getDashboardUrl(dashboardMenuItem.to)"
                    class="link link-nav"
                    target="_blank"
                    @click="close"
                  >
                    <IconsIcon
                      size="medium"
                      :name="dashboardMenuItem.icon"
                    />
                    {{ dashboardMenuItem.label }}
                  </NuxtLink>
                </li>
              </template>
            </ul>

            <button
              type="button"
              class="btn btn-primary mt-8"
              @click="signOut()"
            >
              Uitloggen
            </button>
          </div>
        </div>
        <div
          v-else
          class="ml-auto flex items-center gap-3"
        >
          <a
            :href="dashboardUrl"
            class="link link-nav"
          >
            <IconsPerson />
            Mijn Mozaiek
          </a>
        </div>
      </div>
    </div>
  </nav>

  <AppHeaderMobileMenuContainer
    v-model="isMobileMenuOpen"
    align="end"
  >
    <ul
      v-if="defaultMenu"
    >
      <li
        v-for="(link, index) in defaultMenu"
        :key="index"
      >
        <NuxtLink
          :to="link.url"
          class="link -mx-3 flex rounded-md p-3 hover:bg-primary/5"
          active-class="bg-primary/5 text-primary"
          @click="close"
        >
          {{ link.label }}
        </NuxtLink>
      </li>
    </ul>

    <SearchBar class="mx-auto mt-3" />
  </AppHeaderMobileMenuContainer>

  <AppHeaderMobileMenuContainer
    v-if="user"
    v-model="isProfileOpen"
    align="end"
    :dark="true"
  >
    <AppHeaderProfileUser
      class="mb-4 outline-white"
    />

    <ul>
      <template
        v-for="dashboardMenuItem in dashboardMenu"
        :key="dashboardMenuItem.label"
      >
        <li v-if="dashboardMenuItem.if?.() ?? true">
          <NuxtLink
            class="link link-nav -mx-3 flex rounded-md p-3 hover:bg-white/5"
            active-class="bg-primary/5"
            :to="getDashboardUrl(dashboardMenuItem.to)"
            target="_blank"
            @click="close"
          >
            <IconsIcon
              size="medium"
              :name="dashboardMenuItem.icon"
            />
            {{ dashboardMenuItem.label }}
          </NuxtLink>
        </li>
      </template>
    </ul>

    <AppHeaderMenuWebsiteLinks
      link-classes="link link-nav -mx-3 flex rounded-md p-3 hover:bg-white/5"
      class="mt-3"
    />

    <UiButton
      class="mx-auto mt-4 block"
      label="Uitloggen"
      @click="signOut"
    />
  </AppHeaderMobileMenuContainer>
</template>

<script lang="ts" setup>
import {DEFAULT_MENU} from '@constants/menu';

defineProps<{
  dark?: boolean
}>();

const {menu: defaultMenu} = useSofieMenu(DEFAULT_MENU);

const {tenant} = await useTenant();
const dashboardUrl = computed(() => getDashboardUrl('/'));

const dashboardMenu = useBridgeMenu();

const searchOpen = ref(false);
const isMobileMenuOpen = ref(false);
const isProfileOpen = ref(false);

const close = () => {
  isMobileMenuOpen.value = false;
  isProfileOpen.value = false;
  searchOpen.value = false;
};

const {signOut} = useAuth();
const user = useSofieUserWhenAuthenticated();

const route = useRoute();

watch(route, () => close());
watch(isProfileOpen, () => {
  if (isProfileOpen.value && !user.value) {
    isProfileOpen.value = false;

    window.location.href = dashboardUrl.value;
  }
});

watch(user, () => {
  if (!user.value) {
    isProfileOpen.value = false;
  }
});

const {y} = useWindowScroll();
const isScrolled = computed(() => y.value > 120);
</script>

<style scoped>
.nav-shadow-bottom {
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 5%));
}

.menu-shadow {
  box-shadow: 2px 2px 4px 2px rgba(0 0 0 / 10%);
}

.nav-shadow-side {
  filter: drop-shadow(-2px 4px 2px rgb(0 0 0 / 10%));
}
</style>
