<template>
  <div>
    <AppHeader />

    <main class="h-full min-h-full w-full bg-sand">
      <slot />
    </main>

    <AppFooter />
  </div>
</template>
