import type {MenuItem} from '#types/menu';
import {ProfielStatusEnum} from '@graphql/graphql';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export function useBridgeMenu(): WithRequired<MenuItem, 'icon'>[] {
  const user = useSofieUserWhenAuthenticated();

  return [
    {label: 'Overzicht', icon: 'dashboard', to: '/'},
    {label: 'Profiel', icon: 'my-profile', to: '/profiel'},
    {label: 'Roosters', icon: 'roosters', to: '/rooster'},
    {label: 'Communities', icon: 'my-teams', to: '/communities'},
    {label: 'Gemeente', icon: 'my-congregation', to: '/gemeente', if: () => user.value?.profile.status !== ProfielStatusEnum.Guest},
    {label: 'Tickets', icon: 'my-tickets', to: '/tickets'},
    {label: 'Beheer', icon: 'setting-dashboard', to: '/beheer', if: () => (user.value?.roles?.length ?? 0) > 0},
  ];
}
