<template>
  <footer>
    <div class="bg-black-900 pb-8 pt-12 font-light tracking-[-1.5%] text-white lg:pb-24 lg:pt-40">
      <div class="container">
        <div class="columns-2 md:columns-3 lg:columns-4 [&>*]:mb-10">
          <template
            v-for="(menuItem, index) in menu"
            :key="index"
          >
            <AppFooterSectionAddress
              v-if="['{{ADRES}}', '{{ADDRESS}}'].includes(menuItem.label.toUpperCase())"
            />

            <AppFooterSectionBreak
              v-else-if="'{{BREAK}}' === menuItem.label.toUpperCase()"
            />

            <AppFooterSectionSocial
              v-else-if="'{{SOCIAL}}' === menuItem.label.toUpperCase()"
            />

            <div
              v-else
              :class="{
                'break-inside-avoid': menuItem.children?.length <= 8,
              }"
            >
              <div class="mb-4 font-extrabold lg:mb-4">
                <AppLink
                  v-if="menuItem.url && menuItem.url !== '#'"
                  :label="menuItem.label"
                  :to="menuItem.url"
                />
                <template v-else>
                  {{ menuItem.label }}
                </template>
              </div>

              <nav class="flex flex-col gap-4">
                <template
                  v-for="child in menuItem.children"
                  :key="child.label"
                >
                  <AppLink
                    v-if="child.url && child.url !== '#'"
                    :label="child.label"
                    :to="child.url"
                  />
                  <template v-else>
                    {{ child.label }}
                  </template>
                </template>
              </nav>
            </div>
          </template>
        </div>

        <AppFooterInfo
          class="mt-6 lg:mt-10"
          :links="menuLegal"
        />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const {menu} = useSofieMenu('Footer');
const {menu: menuLegal} = useSofieMenu('FooterLegal');
</script>
