<template>
  <div class="break-inside-avoid">
    <div class="mb-4 font-extrabold">
      Ons kantoor
    </div>
    <ul>
      <li>Mozaiek Centrum</li>
      <li>{{ settings.address }}</li>
      <li>{{ settings.postal_code }} {{ settings.city }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const {settings} = useSofieSettings();
</script>
