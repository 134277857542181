<template>
  <TransitionFade>
    <div
      v-show="modelValue"
      class="pointer-events-none fixed inset-0 z-[1101] h-screen lg:hidden"
    >
      <div
        :class="['pointer-events-none flex', align === 'start' ? 'justify-start' : 'justify-end'] "
      >
        <button
          aria-label="Sluit menu"
          class="pointer-events-auto h-[60px] px-5 py-4"
          :class="[dark ? 'bg-black-900 text-white' : 'bg-white text-black']"
          type="button"
          @click="close"
        >
          <IconsClose height="18" />
        </button>
      </div>
      <TransitionSlide :direction="align === 'start' ? 'right' : 'left'">
        <div
          v-show="modelValue"
          class="pointer-events-auto h-[calc(100vh_-_60px)] overflow-y-auto pb-8 pt-5 font-extrabold"
          :class="[dark ? 'dark bg-black-900 text-white' : 'bg-white text-black']"
        >
          <div class="container text-sm">
            <slot />
          </div>
        </div>
      </TransitionSlide>
    </div>
  </TransitionFade>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  align: 'start' | 'end'
  dark?: boolean
  modelValue: boolean
}>(), {
  dark: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>();

function close() {
  emit('update:modelValue', false);
}
</script>
