<template>
  <template v-if="tenant">
    <NuxtLink
      :class="linkClasses"
      :to="`https://${tenant.domain}/geven`"
      target="_blank"
    >
      <UiIcon
        name="link-external"
        size="medium"
      />
      Geven
    </NuxtLink>
    <NuxtLink
      :class="linkClasses"
      :to="`https://${tenant.domain}`"
      target="_blank"
    >
      <UiIcon
        name="link-external"
        size="medium"
      />
      {{ tenant.title }}
    </NuxtLink>
  </template>

  <NuxtLink
    :class="linkClasses"
    to="https://mozaiek.nl"
    target="_blank"
  >
    <UiIcon
      name="link-external"
      size="medium"
    />
    Mozaiek.nl
  </NuxtLink>
</template>

<script setup lang="ts">
const user = useSofieUserWhenAuthenticated();
const tenant = computed(() => user.value?.profile?.tenant ?? user.value?.tenant ?? null);

withDefaults(defineProps<{
  linkClasses?: string,
}>(), {
  linkClasses: 'link',
});
</script>
