<template>
  <div
    class="relative"
  >
    <div class="relative z-10 flex items-center">
      <input
        ref="inputRef"
        v-model="query"
        class="border-b-px -mr-4 border-x-0 border-t-0 border-black pl-0 pr-3 font-normal outline-none transition-all duration-300 placeholder:text-black-500 focus:border-black focus:ring-0"
        :class="[isOpen ? 'w-60' : 'w-0 opacity-0']"
        type="text"
        placeholder="Waar ben je naar op zoek?"
        @focus="isOpen = true"
        @blur="isOpen = false"
        @keydown.enter="search"
      >

      <icons-icon
        :name="hasQuery && isOpen ? 'close' : 'search'"
        class="link link-nav relative"
        size="medium"
        @click="toggle"
      />
    </div>

    <div
      v-if="false && hasQuery"
      class="menu-shadow shadow-2xl absolute -inset-x-4 -top-4 bg-white px-4 pt-16"
    >
      <!-- TODO: Autocomplete results -->
    </div>
  </div>
</template>

<script setup lang="ts">
const inputRef = ref<HTMLInputElement>();
const query = ref(useRoute().params.query ?? '');
const hasQuery = computed(() => query.value.length > 0);
const isOpen = ref(hasQuery.value);

const emit = defineEmits(['searched']);

function toggle() {
  if (isOpen.value) {
    query.value = '';
  } else {
    nextTick(() => inputRef.value?.focus());
  }

  isOpen.value = !isOpen.value;
}

function search() {
  navigateTo({
    name: 'zoeken',
    query: {
      query: query.value,
    },
  });

  isOpen.value = false;
  query.value = '';

  emit('searched');
}
</script>
