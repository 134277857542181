<template>
  <div class="break-inside-avoid">
    <div class="mb-4 font-extrabold">
      Volg ons
    </div>

    <nav class="flex gap-4">
      <NuxtLink
        v-for="social in socials"
        :key="social.to"
        :to="social.to"
        :aria-label="social.label"
        target="_blank"
        class="link"
        rel="noopener noreferrer nofollow"
      >
        <UiIcon
          :name="social.icon"
          size="medium"
        />
      </NuxtLink>
    </nav>
  </div>
</template>

<script setup lang="ts">
const {settings} = useSofieSettings();

const socials = computed(() => [
  {icon: 'instagram', label: 'Instagram', to: `https://instagram.com/${settings.value.instagram}/`, value: settings.value.instagram},
  {icon: 'facebook', label: 'Facebook', to: `https://facebook.com/${settings.value.facebook}/`, value: settings.value.facebook},
  {icon: 'youtube', label: 'YouTube', to: settings.value.youtube, value: settings.value.youtube},
  {icon: 'twitter', label: 'Twitter', to: `https://twitter.com/${settings.value.twitter}/`, value: settings.value.twitter},
  {icon: 'spotify', label: 'Spotify', to: settings.value.spotify, value: settings.value.spotify},
].filter(({value}) => !!value));
</script>
