<template>
  <div class="w-full">
    <label
      for="search"
      class="sr-only"
    >Zoeken</label>
    <div class="relative">
      <div
        class="absolute inset-y-0 flex cursor-pointer items-center"
        @click="search"
      >
        <icons-search />
      </div>
      <FormKit
        type="form"
        :actions="false"
        @submit="search"
      >
        <FormKit
          v-model="searchQuery"
          type="text"
          name="search"
          inner-class="border-0 pl-6"
          input-class="placeholder:text-black pt-3"
          placeholder="Zoeken"
        />
      </FormKit>
    </div>
  </div>
</template>

<script setup lang="ts">
const searchQuery = ref(useRoute().params.query ?? '');

const search = () => {
  navigateTo({
    name: 'zoeken',
    query: {
      query: searchQuery.value,
    },
  });
};
</script>
